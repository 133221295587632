import { Component, OnInit } from '@angular/core';
import { imagesTypes } from '@ui/legacy-lib';
import { ReactiveFormsModule } from '@angular/forms';
import {
  AppInputDirective,
  FormFieldComponent,
  FormFieldLabelComponent
} from '@ui/legacy-lib';
import { TranslateModule } from '@ngx-translate/core';
import { AutofocusDirective } from '@ui/legacy-lib';
import { EditLandlordWizard } from '../edit-landlord-wizard';

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss'],
  imports: [
    ReactiveFormsModule,
    FormFieldComponent,
    FormFieldLabelComponent,
    TranslateModule,
    AppInputDirective,
    AutofocusDirective
  ],
  standalone: true
})
export class AuthenticationComponent
  extends EditLandlordWizard
  implements OnInit
{
  public acceptedImagesTypes = imagesTypes.join(',');

  ngOnInit() {
    super.ngOnInit();
  }
}
