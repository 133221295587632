import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject
} from '@angular/core';
import { Store } from '@ngrx/store';
import { FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, filter, tap, withLatestFrom } from 'rxjs/operators';

import {
  Addon,
  AddonDiscount,
  CreateSubscriptionPayload,
  GenericFormControl,
  InvoiceSource,
  LandlordUser,
  PaymentInvoice,
  PaymentMethodType,
  Product,
  ProductOverview,
  QuotaDiscount,
  QuotaPackage,
  UpdateSubscriptionsPayload
} from '@ui/shared/models';
import { ActionState } from '@ui/legacy-lib';

import * as fromAppState from 'admin/+state/index';

import { ADDON_DISCOUNT_CONFIG } from '@ui/legacy-lib';
import { SubscriptionPageComponent } from '@ui/legacy-lib';
import { AsyncPipe } from '@angular/common';
import * as fromStateLandlordsSelectors from '../../../+state/landlords/landlords.selectors';
import * as fromStateReducer from '../../../+state/reducers';
import { EditLandlordWizard } from '../edit-landlord-wizard';

@UntilDestroy()
@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss'],
  imports: [SubscriptionPageComponent, AsyncPipe],
  standalone: true
})
export class SubscriptionComponent
  extends EditLandlordWizard
  implements OnInit
{
  private store = inject<Store<fromStateReducer.EditLandlordState>>(Store);

  @Input() userData: LandlordUser;
  @Input() hasActiveProduct: boolean;
  @Input() newPaymentMethod: PaymentMethodType;
  @Input() landlordActionState: ActionState;
  @Input() recalculationActionState$: Observable<ActionState>;

  @Output() finishEditLandlord = new EventEmitter();
  @Output() saveQuotaDiscounts = new EventEmitter<QuotaDiscount[]>();
  @Output() saveObjectContingentQuotaDiscounts = new EventEmitter<
    QuotaDiscount[]
  >();
  @Output() discountChange = new EventEmitter<AddonDiscount[]>();
  @Output() update = new EventEmitter();
  @Output() create = new EventEmitter();
  @Output() createLandlord = new EventEmitter();

  public destroy$: Subject<void>;

  public loaded: boolean;

  public products: Product[];
  public addons: Addon[];
  public defaultProductId: string;

  public customerProduct$: Observable<ProductOverview>;
  public customerProductActionState$: Observable<ActionState>;

  public defaultProduct$: Observable<Product>;
  public nextProductId$: Observable<string>;

  public recalculatingPrices$: Observable<ActionState>;
  public productActionState$: Observable<ActionState>;
  public subscriptionActionState$: Observable<ActionState>;
  public updateLandlordActionState$: Observable<ActionState>;

  public addonDiscounts: AddonDiscount[];
  public digitalContractQuotaDiscounts$: Observable<QuotaDiscount[]>;
  public digitalContractQuotaPackages$: Observable<QuotaPackage[]>;
  public objectContingentQuotaDiscounts$: Observable<QuotaDiscount[]>;
  public objectContingentQuotaPackages$: Observable<QuotaPackage[]>;
  public currency = 'EUR';

  get paymentDetailsForm() {
    return this.form.get('paymentDetails') as FormGroup;
  }

  public get productControl() {
    return this.form.get('product') as GenericFormControl<Product>;
  }

  public get addonsControl() {
    return this.form.get('addons') as GenericFormControl<Addon[]>;
  }

  get paymentMethodsFromLL() {
    if (
      this.userData?.customer?.paymentMethods?.length === 0 ||
      !this.userData?.customer?.paymentMethods?.find(
        pm => pm.preferred && pm.method !== 'DEFAULT'
      )
    ) {
      return this.newPaymentMethod;
    }
    return this.userData?.customer?.paymentMethods?.find(
      pm => pm.preferred && pm.method !== 'DEFAULT'
    ).method;
  }

  get paymentMethod() {
    return this.mapPaymentMethod({
      type: this.paymentMethodsFromLL,
      invoiceEmail: this.userData?.customer.invoiceEmail
    } as InvoiceSource);
  }

  get priceMultiplierControl() {
    return this.paymentDetailsForm.get('priceMultiplier');
  }

  get nextPriceMultiplierControl() {
    return this.paymentDetailsForm.get('nextPriceMultiplier');
  }

  get discountControl() {
    return this.paymentDetailsForm.get('discount');
  }

  get discountEndControl() {
    return this.paymentDetailsForm.get('discountEnd');
  }

  private get getAddonDiscountPayload() {
    const otherAddons = this.addons.filter(a => {
      return !this.addonsControl.value.find(
        basketAddon => basketAddon.id === a.id
      );
    });

    const discountPayload = [
      ...(this.addonsControl?.value || []),
      ...(otherAddons || [])
    ]?.map(a => ({
      addonId: a.id,
      value: a?.discount?.value || 0,
      endDate: a?.discount?.endDate || ''
    }));

    return discountPayload || [];
  }

  ngOnInit() {
    super.ngOnInit();

    this.customerProduct$ = this.store.select(fromAppState.getLandlordProduct);
    this.customerProductActionState$ = this.store.select(
      fromAppState.getLandlordProductsActionState
    );

    combineLatest([
      this.store.select(fromAppState.getDefaultProductActionState),
      this.store.select(fromAppState.getDefaultProductId)
    ])
      .pipe(
        filter(([state]) => !state.pending),
        tap(([state]) => (this.loaded = state.done)),
        withLatestFrom(this.store.select(fromAppState.getDefaultProducts)),
        untilDestroyed(this)
      )
      .subscribe(([[_, defaultId], defaultProducts]) => {
        if (
          !this.userData?.customer?.id ||
          !this.userData?.customer?.hasActiveProduct
        ) {
          this.store.dispatch(new fromAppState.CreateProduct());
        }
        this.defaultProductId = defaultId;
        this.products = defaultProducts.map(p => p.product);
        this.addons = defaultProducts.find(
          product => product.product.id === defaultId
        )?.addons;
      });

    this.store
      .select(fromAppState.getDefaultProducts)
      .pipe(
        withLatestFrom(this.store.select(fromAppState.getDefaultProductId)),
        untilDestroyed(this)
      )
      .subscribe(([defaultProducts, defaultId]) => {
        this.addons = defaultProducts.find(
          product => product.product.id === defaultId
        )?.addons;
      });

    this.recalculatingPrices$ = this.store.select(
      fromAppState.getRecalculatingPricesActionState
    );

    this.subscriptionActionState$ = this.store.select(
      fromAppState.getSubscriptionActionState
    );

    this.updateLandlordActionState$ = this.store.select(
      fromStateLandlordsSelectors.getCRUDActionState
    );

    this.nextProductId$ = this.store.select(
      fromStateLandlordsSelectors.getNextProductId
    );

    this.digitalContractQuotaDiscounts$ = this.store.select(
      fromAppState.getDigitalContractQuotaDiscounts
    );

    this.digitalContractQuotaPackages$ = this.store.select(
      fromAppState.getDigitalContractQuotaPackages
    );

    this.objectContingentQuotaDiscounts$ = this.store.select(
      fromAppState.getObjectContingentQuotaDiscounts
    );

    this.objectContingentQuotaPackages$ = this.store.select(
      fromAppState.getObjectContingentQuotaPackages
    );

    this.store
      .select(fromAppState.getAddonDiscounts)
      .pipe(untilDestroyed(this))
      .subscribe(addonDiscount => (this.addonDiscounts = addonDiscount));

    this.priceMultiplierControl.valueChanges
      .pipe(debounceTime(500), untilDestroyed(this))
      .subscribe((value: number) => {
        this.store.dispatch(
          new fromAppState.RecalculateAddonsPrice({
            addonDiscounts: this.getAddonDiscountPayload,
            priceMultiplier: value,
            nextPriceMultiplier: this.nextPriceMultiplierControl.value,
            discount: this.discountControl.value,
            discountEndDate: this.discountEndControl.value
          })
        );
      });

    this.nextPriceMultiplierControl.valueChanges
      .pipe(debounceTime(500), untilDestroyed(this))
      .subscribe((value: number) => {
        this.store.dispatch(
          new fromAppState.RecalculateAddonsPrice({
            addonDiscounts: this.getAddonDiscountPayload,
            priceMultiplier: this.priceMultiplierControl.value,
            nextPriceMultiplier: value,
            discount: this.discountControl.value,
            discountEndDate: this.discountEndControl.value
          })
        );
      });

    this.discountEndControl.valueChanges
      .pipe(debounceTime(500), untilDestroyed(this))
      .subscribe((value: Date) => {
        this.store.dispatch(
          new fromAppState.RecalculateAddonsPrice({
            addonDiscounts: this.getAddonDiscountPayload,
            priceMultiplier: this.priceMultiplierControl.value,
            nextPriceMultiplier: this.nextPriceMultiplierControl.value,
            discount: this.discountControl.value,
            discountEndDate: value
          })
        );
      });

    this.discountControl.valueChanges
      .pipe(debounceTime(500), untilDestroyed(this))
      .subscribe((value: number) => {
        this.store.dispatch(
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          new fromAppState.RecalculateAddonsPrice({
            addonDiscounts: this.getAddonDiscountPayload,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            priceMultiplier: this.priceMultiplierControl.value,
            nextPriceMultiplier: this.nextPriceMultiplierControl.value,
            discount: value,
            discountEndDate: this.discountEndControl.value
          })
        );
      });
  }

  public onUpdate(data: UpdateSubscriptionsPayload) {
    this.update.emit(data);
  }

  public onCreate(data: CreateSubscriptionPayload) {
    this.create.emit(data);
  }

  public complete() {
    this.finishEditLandlord.emit();
  }

  public onProductChange(id: string) {
    if (this.userData?.customer?.hasActiveProduct) {
      this.store.dispatch(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        new fromAppState.UpdateNextProduct(id, this.userData?.customer?.id)
      );
    } else {
      this.store.dispatch(new fromAppState.SelectDefaultProduct(id));
      this.store.dispatch(new fromAppState.SelectProduct(id));
      this.store.dispatch(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        new fromAppState.RecalculateAddonsPrice({
          addonDiscounts: this.getAddonDiscountPayload,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          priceMultiplier: this.priceMultiplierControl.value,
          nextPriceMultiplier: this.nextPriceMultiplierControl.value,
          discount: this.discountControl.value,
          discountEndDate: this.discountEndControl.value
        })
      );
    }
  }

  public onDiscountChange(discounts: AddonDiscount) {
    if (
      discounts.value < ADDON_DISCOUNT_CONFIG.minValue &&
      discounts.value > ADDON_DISCOUNT_CONFIG.maxValue
    ) {
      return;
    }

    this.store.dispatch(
      new fromAppState.RecalculateAddonPrice({
        addonId: discounts.addonId,
        addonDiscount: discounts.value,
        addonDiscountEndDate: discounts.endDate,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        discount: this.discountControl.value,
        discountEndDate: this.discountEndControl.value,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        priceMultiplier: this.priceMultiplierControl.value,
        nextPriceMultiplier: this.nextPriceMultiplierControl.value
      })
    );
  }

  public onSaveQuotaDiscounts(discounts: QuotaDiscount[]) {
    this.saveQuotaDiscounts.emit(discounts);
  }

  public onSaveObjectContingentQuotaDiscounts(discounts: QuotaDiscount[]) {
    this.saveObjectContingentQuotaDiscounts.emit(discounts);
  }

  public onCreateLandlord() {
    this.createLandlord.emit();
  }

  private mapPaymentMethod(source: any) {
    const typename = source.type;

    if (typename === PaymentMethodType.INVOICE) {
      return new PaymentInvoice(source as InvoiceSource);
    }
    throw new Error(
      `UNRECOGNIZED PAYMENT (source.__typename): ${String(typename)}`
    );
  }
}
