import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  inject
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import moment from 'moment';

import { Observable } from 'rxjs';
import {
  filter,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom
} from 'rxjs/operators';

import { customEmailValidator, Go, minDateValidator } from '@ui/legacy-lib';
import { Step } from '@ui/legacy-lib';
import {
  Addon,
  AddonBasketItem,
  AddonDiscount,
  CreateSubscriptionPayload,
  CustomerSizePriceMultiplier,
  CustomerType,
  GenericFormControl,
  LandlordCustomerSize,
  LandlordUser,
  PaymentMethodType,
  ProductOverview,
  QuotaDiscount,
  UpdateSubscriptionsPayload
} from '@ui/shared/models';
import { ActionState } from '@ui/legacy-lib';
import { isValueNotNullAndUndefined, RegexTypes } from '@ui/legacy-lib';

import * as fromAppState from 'admin/+state';
import { EditLandlordStepHandler } from 'admin/screens/landlord/components/edit-landlord/edit-landlord-step-handler';
import { LandlordForm, LandlordUserInput } from 'admin/screens/landlord/models';

import { GERMAN_COUNTRY_CODE } from '@ui/legacy-lib';
import { WizardComponent, WizardStepDirective } from '@ui/legacy-lib';
import { AuthenticationComponent } from 'admin/screens/landlord/components/edit-landlord/authentication/authentication.component';
import { TranslateModule } from '@ngx-translate/core';
import { PreferencesComponent } from 'admin/screens/landlord/components/edit-landlord/preferences/preferences.component';
import { PaymentMethodComponent } from 'admin/screens/landlord/components/edit-landlord/payment-method/payment-method.component';
import { ObjectHierarchyComponent } from 'admin/screens/landlord/components/edit-landlord/object-hierarchy/object-hierarchy.component';
import { SubscriptionComponent } from 'admin/screens/landlord/components/edit-landlord/subscription/subscription.component';
import { AsyncPipe } from '@angular/common';
import * as fromState from '../../+state';

@UntilDestroy()
@Component({
  selector: 'app-edit-landlord',
  templateUrl: './edit-landlord.component.html',
  styleUrls: ['./edit-landlord.component.scss'],
  imports: [
    WizardComponent,
    AuthenticationComponent,
    WizardStepDirective,
    TranslateModule,
    PreferencesComponent,
    PaymentMethodComponent,
    ObjectHierarchyComponent,
    SubscriptionComponent,
    AsyncPipe
  ],
  standalone: true
})
@EditLandlordStepHandler()
export class EditLandlordComponent implements OnInit, AfterViewInit, OnDestroy {
  private store = inject<Store<fromState.EditLandlordState>>(Store);
  private fb = inject(FormBuilder);
  private route = inject(ActivatedRoute);

  /* eslint-disable @typescript-eslint/ban-types */
  public isSelectActionAvailable: Function;
  public getAllSteps: Function;
  public nextStep: Function;
  public selectStep: Function;
  public previousStep: Function;
  public getStepIndex: Function;
  public showStep: Function;
  /* eslint-enable @typescript-eslint/ban-types */

  public processLandlordForm: FormGroup;
  public landlordActionState$: Observable<ActionState>;
  public recalculationActionState$: Observable<ActionState>;
  public currentStep$: Observable<number>;
  public error$: Observable<string>;
  public currentForm$: Observable<FormGroup>;
  public allSteps: Step[];

  public landlordId: string;
  public hasActiveProduct: boolean;
  public userData: LandlordUser;
  public customerProduct: ProductOverview;

  public get authenticationForm() {
    return this.processLandlordForm.get('authentication') as FormGroup;
  }

  public get preferencesForm() {
    return this.processLandlordForm.get('preferences') as FormGroup;
  }

  public get paymentForm() {
    return this.processLandlordForm.get('payment') as FormGroup;
  }

  public get paymentMethod() {
    return this.paymentForm.get('paymentMethod') as FormControl;
  }

  public get subscriptionForm() {
    return this.processLandlordForm.get('subscription') as FormGroup;
  }

  public get paymentDetailsForm() {
    return this.subscriptionForm.get('paymentDetails') as FormGroup;
  }

  public get addonsControl() {
    return this.subscriptionForm.get('addons') as GenericFormControl<Addon[]>;
  }

  public get priceMultiplierControl() {
    return this.paymentDetailsForm.get('priceMultiplier') as FormGroup;
  }

  public get nextPriceMultiplierControl() {
    return this.paymentDetailsForm.get('nextPriceMultiplier') as FormGroup;
  }

  public get discountControl() {
    return this.paymentDetailsForm.get('discount') as FormGroup;
  }

  public get discountEndControl() {
    return this.paymentDetailsForm.get('discountEnd') as FormGroup;
  }

  public get currentLandlordId() {
    return this.route.snapshot.params.landlordId as string;
  }

  public get userDataPayload(): LandlordUser {
    const { paymentMethod, ...payment } = this.paymentForm.value;
    return (this.userData || {
      ...this.authenticationForm.value,
      customer: {
        ...payment,
        ...this.preferencesForm.value,
        paymentMethods: [{ method: paymentMethod, preferred: true }]
      }
    }) as LandlordUser;
  }

  ngOnInit() {
    this.store.dispatch(new fromAppState.LoadAvailableProducts(1));
    this.allSteps = this.getAllSteps();
    this.currentStep$ = this.store.select(fromState.getCurrentStepNumber);
    this.landlordActionState$ = this.store.select(fromState.getCRUDActionState);
    this.recalculationActionState$ = this.store.select(
      fromAppState.getRecalculatingPricesActionState
    );
    this.processLandlordForm = this.fb.group({
      authentication: this.fb.group({
        firstName: [
          '',
          Validators.compose([
            Validators.required,
            Validators.pattern(RegexTypes.TEXT)
          ])
        ],
        lastName: [
          '',
          Validators.compose([
            Validators.required,
            Validators.pattern(RegexTypes.TEXT)
          ])
        ],
        email: new FormControl('', {
          validators: Validators.compose([
            Validators.required,
            customEmailValidator
          ]),
          updateOn: 'blur'
        })
      }),
      preferences: this.fb.group({
        customerType: ['', Validators.required],
        companyName: ['', Validators.required],
        customerSize: [LandlordCustomerSize.PRIVATE, Validators.required],
        domainName: [
          '',
          Validators.compose([
            Validators.required,
            Validators.pattern(RegexTypes.DOMAIN)
          ])
        ]
      }),
      payment: this.fb.group({
        paymentMethod: [PaymentMethodType.INVOICE, Validators.required],
        invoiceEmail: ['', customEmailValidator],
        address: this.fb.group({
          street: [
            '',
            Validators.compose([
              Validators.pattern(RegexTypes.STREET),
              Validators.required
            ])
          ],
          houseNumber: [
            '',
            Validators.compose([
              Validators.pattern(RegexTypes.STREET),
              Validators.required
            ])
          ],
          zipCode: [
            '',
            Validators.compose([
              Validators.pattern(RegexTypes.ZIPCODE),
              Validators.required
            ])
          ],
          district: [
            '',
            Validators.compose([Validators.pattern(RegexTypes.CITY)])
          ],
          city: [
            '',
            Validators.compose([
              Validators.pattern(RegexTypes.CITY),
              Validators.required
            ])
          ],
          region: [
            '',
            Validators.compose([
              Validators.pattern(RegexTypes.REGION),
              Validators.required
            ])
          ],
          country: [
            GERMAN_COUNTRY_CODE,
            Validators.compose([
              Validators.pattern(RegexTypes.COUNTRY),
              Validators.required
            ])
          ]
        })
      }),
      objectHierarchy: this.fb.group({
        mock: [''] // hierarchy is independent from the rest of the form
      }),
      subscription: this.fb.group({
        product: [{}],
        addons: [[]],
        paymentDetails: this.fb.group({
          trialPeriodEndDate: [
            '',
            Validators.compose([minDateValidator(moment().toDate())])
          ],
          priceMultiplier: [
            1,
            Validators.compose([
              Validators.required,
              Validators.pattern(RegexTypes.MULTIPLYFACTOR)
            ])
          ],
          nextPriceMultiplier: [
            null,
            Validators.compose([Validators.pattern(RegexTypes.MULTIPLYFACTOR)])
          ],
          discount: [
            0,
            Validators.compose([Validators.min(0), Validators.max(1)])
          ],
          discountEnd: [
            '',
            Validators.compose([minDateValidator(moment().toDate())])
          ]
        })
      })
    });

    this.route.params
      .pipe(
        tap(params => {
          if (params?.landlordId !== 'new') {
            this.disableFields(!params.landlordId);
          }
          this.preferencesForm.get('domainName').disable();
        }),
        filter(params => !!params.landlordId),
        untilDestroyed(this)
      )
      .subscribe(params => {
        if (params.landlordId !== 'new') {
          this.store.dispatch(new fromState.GetLandlord(params.landlordId));
          this.store.dispatch(new fromState.SelectLandlord(params.landlordId));
        }
      });

    this.currentForm$ = this.currentStep$.pipe(
      map(
        (currentStep: number) =>
          this.processLandlordForm.get(
            this.allSteps[currentStep - 1].name
          ) as FormGroup
      )
    );
    this.store
      .select(fromAppState.getSelectedProduct)
      .pipe(
        filter(product => !!product),
        untilDestroyed(this)
      )
      .subscribe(product => {
        this.subscriptionForm.patchValue({ product });
      });

    this.preferencesForm
      .get('customerSize')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((value: LandlordCustomerSize) => {
        if (
          this.currentLandlordId === 'new' &&
          !this.paymentDetailsForm.touched &&
          isValueNotNullAndUndefined(value)
        ) {
          this.paymentDetailsForm.patchValue({
            priceMultiplier: CustomerSizePriceMultiplier[value]
          });
          this.store.dispatch(
            new fromAppState.LoadAvailableProducts(
              CustomerSizePriceMultiplier[value]
            )
          );
        }
      });

    this.preferencesForm
      .get('customerType')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((value: CustomerType) => {
        if (value === CustomerType.PRIVATE) {
          return this.preferencesForm.get('companyName').disable();
        }

        this.preferencesForm.get('companyName').enable();
      });
  }

  private getAddonDiscountPayload(defaultProduct: ProductOverview) {
    const addons = defaultProduct.addons;
    const otherAddons = addons.filter(a => {
      return !this.addonsControl.value.find(
        basketAddon => basketAddon.id === a.id
      );
    });

    const discountPayload = [
      ...(this.addonsControl?.value || []),
      ...(otherAddons || [])
    ]?.map(a => ({
      addonId: a.id,
      value: a?.discount?.value || 0,
      endDate: a?.discount?.endDate || ''
    }));

    return discountPayload || [];
  }

  public ngAfterViewInit(): void {
    this.store
      .select(fromState.getCRUDActionState)
      .pipe(
        filter(state => !state.pending),
        switchMap(() => this.store.select(fromState.getLandlord)),
        filter(
          landlord =>
            Object.keys(landlord).length > 0 &&
            (landlord.id === this.currentLandlordId || !this.currentLandlordId)
        ),
        tap(landlord => {
          this.store.dispatch(
            new fromAppState.LoadDigitalContractQuotaPackages(
              landlord.customer.id
            )
          );
          this.store.dispatch(
            new fromAppState.LoadDigitalContractQuotaDiscount(
              landlord.customer.id
            )
          );
          this.store.dispatch(
            new fromAppState.LoadObjectContingentQuotaDiscount(
              landlord.customer.id
            )
          );
          this.store.dispatch(
            new fromAppState.LoadObjectContingentQuotaPackages(
              landlord.customer.id
            )
          );
          this.store.dispatch(
            new fromAppState.LoadCustomerProduct(landlord.customer.id)
          );
        }),
        untilDestroyed(this)
      )
      .subscribe(landlord => {
        this.userData = landlord;
        this.hasActiveProduct = this.userData?.customer?.hasActiveProduct;
      });

    this.store
      .select(fromAppState.getLandlordProductsActionState)
      .pipe(
        filter(state => !state.pending && state.done),
        switchMap(() => this.store.select(fromState.getLandlordActionState)),
        filter(state => !state.pending && state.done),
        switchMap(() =>
          this.store
            .select(fromState.getLandlord)
            .pipe(
              withLatestFrom(
                this.store.select(fromAppState.getLandlordProduct),
                this.store.select(fromAppState.getDefaultProduct)
              )
            )
        ),
        untilDestroyed(this)
      )
      .subscribe(([landlord, product, defaultProduct]) => {
        this.customerProduct = product;
        this.patchForm(landlord, product);
        this.store.dispatch(
          new fromAppState.RecalculateAddonsPrice({
            addonDiscounts: this.getAddonDiscountPayload(defaultProduct),
            priceMultiplier: this.priceMultiplierControl.value,
            nextPriceMultiplier: this.nextPriceMultiplierControl.value,
            discount: this.discountControl.value,
            discountEndDate: this.discountEndControl.value
          })
        );
      });

    this.store
      .select(fromAppState.getSubscriptionActionState)
      .pipe(
        filter(state => !state.pending && state.done),
        switchMap(() =>
          this.store
            .select(fromState.getLandlord)
            .pipe(
              withLatestFrom(this.store.select(fromAppState.getLandlordProduct))
            )
        ),
        untilDestroyed(this)
      )
      .subscribe(([landlord, product]) => {
        this.customerProduct = product;
        this.patchForm(landlord, product);
      });
  }

  public ngOnDestroy() {
    this.processLandlordForm.reset();
    this.store.dispatch(new fromState.ResetWizard());
    this.store.dispatch(new fromAppState.ResetProduct());
  }

  public onSaveQuotaDiscounts(discounts: QuotaDiscount[]) {
    this.store.dispatch(
      new fromAppState.SaveDigitalContractQuotaDiscount(
        this.userData?.customer.id,
        discounts
      )
    );
  }

  public onSaveObjectContingentQuotaDiscounts(discounts: QuotaDiscount[]) {
    this.store.dispatch(
      new fromAppState.SaveObjectContingentQuotaDiscount(
        this.userData?.customer.id,
        discounts
      )
    );
  }

  public onCreateAccount() {
    const { subscription } = this.processLandlordForm.value as LandlordForm;
    const {
      paymentDetails: {
        priceMultiplier,
        nextPriceMultiplier,
        trialPeriodEndDate
      },
      product
    } = subscription;
    const addons: AddonBasketItem[] = subscription.addons
      .filter(a => isValueNotNullAndUndefined(a?.amounts?.renew))
      .reduce((total, current) => {
        return [
          ...total,
          { productAddonID: current.id, quantity: current.amounts.renew }
        ];
      }, [] as AddonBasketItem[]);

    const savePayload = this.getPayloadToSave();
    const payload: LandlordUserInput = {
      ...savePayload,
      customer: {
        ...savePayload.customer,
        priceMultiplier,
        nextPriceMultiplier,
        abo: {
          product: product.id,
          trialPeriodEndDate,
          addons
        }
      }
    };

    this.store.dispatch(new fromState.CreateLandlord(payload));

    this.landlordActionState$
      .pipe(
        filter(state => state.done),
        switchMap(() => this.store.select(fromState.getLandlord)),
        take(1)
      )
      .subscribe(landlord => {
        this.userData = landlord;
        this.onComplete();
      });
  }

  public onComplete() {
    this.store.dispatch(
      new Go({
        path: ['/tables/landlord/customers'],
        query: { customerId: this.userData?.customer.id }
      })
    );
    this.processLandlordForm.reset();
    this.store.dispatch(new fromState.ResetWizard());
    this.store.dispatch(new fromAppState.ResetProduct());
  }

  public onSaveAccountAndDiscount() {
    const payload = this.getPayloadToSave();
    const id =
      this.currentLandlordId === 'new'
        ? this.userData.id
        : this.currentLandlordId;
    return this.store.dispatch(new fromState.UpdateLandlord(payload, id));
  }

  public onUpdate(data: UpdateSubscriptionsPayload) {
    const { addonTypesDiff, ...payload } = data;
    this.onSaveAccountAndDiscount();
    if (
      !payload.trialPeriodEndDateChanged &&
      payload.purchasedAddons?.length === 0 &&
      payload.discardedAddons?.length === 0
    ) {
      return;
    }
    this.landlordActionState$
      .pipe(
        filter(state => !state.pending),
        take(1)
      )
      .subscribe(() => {
        this.store.dispatch(
          new fromAppState.UpdateSubscription(
            {
              ...payload,
              customerID: this.userData?.customer.id,
              paymentMethod: this.userData?.customer.paymentMethods.find(
                pm => pm.preferred
              ).method
            },
            this.userData.id
          )
        );
      });
  }

  public onCreate(data: CreateSubscriptionPayload) {
    this.onSaveAccountAndDiscount();
    this.landlordActionState$
      .pipe(
        filter(state => !state.pending),
        take(1)
      )
      .subscribe(() => {
        this.store.dispatch(
          new fromAppState.CreateSubscription(data, this.userData.id)
        );
      });
  }

  private patchForm(landlord: LandlordUser, landlordProduct: ProductOverview) {
    let valueToPatch = {};
    if (landlord && Object.keys(landlord).length > 0) {
      const {
        email,
        profile: { firstname, name },
        customer: {
          paymentMethods,
          invoiceEmail,
          address,
          customerSize,
          customerType,
          name: companyName
        }
      } = landlord;
      const paymentMethod = paymentMethods.find(method => method.preferred);

      valueToPatch = {
        ...valueToPatch,
        authentication: {
          firstName: firstname,
          lastName: name,
          email
        },
        payment: {
          paymentMethod: paymentMethod
            ? paymentMethod.method
            : PaymentMethodType.INVOICE,
          address: address || {},
          invoiceEmail
        },
        preferences: {
          companyName,
          customerType,
          customerSize
          // domainName: '' // n/a now - we do not have full support now
        }
      };
    }
    if (Object.keys(landlordProduct || {})?.length > 0) {
      valueToPatch = {
        ...valueToPatch,
        subscription: {
          product: landlordProduct.product,
          addons: landlordProduct?.addons || [],
          paymentDetails: {
            priceMultiplier:
              landlord?.customer?.priceMultiplier ||
              this.priceMultiplierControl.value,
            nextPriceMultiplier:
              landlord?.customer?.nextPriceMultiplier ||
              this.nextPriceMultiplierControl.value,
            trialPeriodEndDate: landlordProduct?.dueDate,
            discount: landlordProduct?.discount?.value,
            discountEnd:
              landlordProduct?.discount?.endDate &&
              moment(landlordProduct?.discount?.endDate).format('YYYY-MM-DD')
          }
        }
      };
      this.store.dispatch(
        new fromAppState.SelectProduct(landlordProduct?.product?.id)
      );
    }

    this.processLandlordForm.patchValue(valueToPatch);
  }

  private disableFields(isNew: boolean) {
    const email = this.authenticationForm.get('email');

    if (isNew) {
      email.enable();
      return;
    }
    email.disable();
  }

  private getPayloadToSave() {
    const discount = this.customerProduct?.discount;
    const discountModel = this.paymentDetailsForm.get('discount');
    const discountEndModel = this.paymentDetailsForm.get('discountEnd');
    const addonsDiscounts = this.addonsControl?.value?.map(addon => ({
      addonId: addon?.id,
      value: addon?.discount?.value,
      endDate: addon?.discount?.endDate
    })) as AddonDiscount[];
    const hasDiscountChanged =
      !discount ||
      discountModel.value !== discount?.value ||
      moment(discountEndModel.value).format('L') !==
        moment(discount?.endDate).format('L');

    const {
      authentication: { logo, ...authentication },
      preferences: { domainName, companyName: name, ...preferences },
      payment,
      subscription: {
        paymentDetails: { priceMultiplier, nextPriceMultiplier }
      }
    } = this.processLandlordForm.value as LandlordForm;

    return {
      ...authentication,
      product: {
        addonsDiscounts
      },
      customer: {
        ...preferences,
        ...payment,
        name,
        priceMultiplier,
        nextPriceMultiplier,
        discount: hasDiscountChanged ? discountModel.value : undefined,
        discountEnd: hasDiscountChanged ? discountEndModel.value : undefined,
        discountId: discount && discount.id,
        location: 'DE'
      }
    } as LandlordUserInput;
  }
}
