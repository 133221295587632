import { Component, OnInit, inject } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import {
  CustomerType,
  LandlordCustomerSize,
  NameValue
} from '@ui/shared/models';
import { ReactiveFormsModule } from '@angular/forms';
import {
  AppInputDirective,
  FormFieldComponent,
  FormFieldLabelComponent,
  HintComponent,
  SelectComponent
} from '@ui/legacy-lib';
import { AutofocusDirective } from '@ui/legacy-lib';
import { EditLandlordWizard } from '../edit-landlord-wizard';

const BUSINESS_TYPES_VALUES = [
  { name: 'business.estate_agent_l', value: CustomerType.ESTATEAGENT },
  { name: 'business.private_l', value: CustomerType.PRIVATE },
  {
    name: 'business.property_management_l',
    value: CustomerType.PROPERTYMANAGEMENT
  },
  { name: 'business.other_l', value: CustomerType.OTHER }
];

const PRICING_PLAN = [
  { name: 'pricingplan.private_l', value: LandlordCustomerSize.PRIVATE },
  { name: 'pricingplan.small_l', value: LandlordCustomerSize.SMALL },
  { name: 'pricingplan.medium_l', value: LandlordCustomerSize.MEDIUM },
  { name: 'pricingplan.large_l', value: LandlordCustomerSize.LARGE },
  { name: 'pricingplan.enterprise_l', value: LandlordCustomerSize.ENTERPRISE }
];

@Component({
  selector: 'app-preferences',
  templateUrl: './preferences.component.html',
  styleUrls: ['./preferences.component.scss'],
  imports: [
    ReactiveFormsModule,
    FormFieldComponent,
    FormFieldLabelComponent,
    TranslateModule,
    SelectComponent,
    HintComponent,
    AppInputDirective,
    AutofocusDirective
  ],
  standalone: true
})
export class PreferencesComponent extends EditLandlordWizard implements OnInit {
  private translate = inject(TranslateService);

  businessTypes: NameValue<string>[] = BUSINESS_TYPES_VALUES;
  pricingPlan: NameValue<string>[];

  get customerTypeControl() {
    return this.form.get('customerType');
  }

  get companyNameControl() {
    return this.form.get('companyName');
  }

  get isPrivate() {
    return this.customerTypeControl.value === CustomerType.PRIVATE;
  }

  ngOnInit() {
    super.ngOnInit();
    this.businessTypes = BUSINESS_TYPES_VALUES.map(({ name, value }) => ({
      value,
      name: this.translate.instant(name)
    }));
    this.pricingPlan = PRICING_PLAN.map(({ name, value }) => ({
      value,
      name: this.translate.instant(name)
    }));

    this.customerTypeControl.valueChanges.subscribe(type => {
      if (type === CustomerType.PRIVATE) {
        this.companyNameControl.reset();
        this.companyNameControl.disable();
      } else {
        this.companyNameControl.enable();
      }
    });
  }
}
