import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';

import { Go } from '@ui/legacy-lib';
import { getCompletedStep } from '../+state/landlords/landlords.selectors';
import { EditLandlordState } from '../+state/reducers';

@Injectable()
export class EditLandlordStepsGuard {
  private store = inject<Store<EditLandlordState>>(Store);

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const requireCompleted = route.data.requireCompletion;
    return this.store.select(getCompletedStep).pipe(
      map(completedSteps => {
        if (completedSteps.includes(requireCompleted)) return true;
        const landlordId = (route.params.landlordId ||
          state.url.split('/')[2]) as string;
        if (landlordId && landlordId !== 'create') {
          this.store.dispatch(
            new Go({
              path: [`landlord/${landlordId}/edit/authentication`]
            })
          );
          return true;
        }
        this.store.dispatch(
          new Go({
            path: [`landlord/create/${String(requireCompleted)}`]
          })
        );
      })
    );
  }
}
