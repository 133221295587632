import { OnInit, Input, Directive } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Step } from '@ui/legacy-lib';
import { WizardStep } from '@ui/legacy-lib';

@Directive({ standalone: true })
export class EditLandlordWizard extends WizardStep implements OnInit {
  @Input() form: FormGroup;
  @Input() stepIndex: number;
  @Input() steps: Step[];

  ngOnInit() {
    super.ngOnInit();
  }

  nextStep() {
    const stepName = this.steps[this.stepIndex].name;
    this.onNextStep.emit(stepName);
  }
}
